import React, { useEffect, useState } from 'react';
// Change useHistory to useNavigate
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import RestApi from '../Apis/RestApi';
import { toast } from 'react-toastify';

const EmailVerification = () => {
  const [message, setMessage] = useState('');
  // Change useHistory to useNavigate
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    const verifyEmail = async () => {
      try {
        const response = await RestApi.verifyEmail(token);
        toast.success(response.data.message);

        // Use navigate instead of history.push
        if (response.data.redirectUrl) {
          navigate(response.data.redirectUrl);  // Redirects the user to the login page
        }
      } catch (error) {
        toast.error('Verification failed',error.response?.data?.message);
      }
    };

    if (token) {
      verifyEmail();
    }
  }, [navigate]);

  return (
    <div className='h-screen w-screen bg-slate-900 flex justify-center items-center'>
     <div className=' '>
     <h1 className='text-white text-7xl mb-8' >Email Verification</h1>
     <p className='text-white text-2xl'>{message?message:"Check your mail and verify your email and start making Smart QR codes."}</p>
     </div>
    </div>
  );
};

export default EmailVerification;
