import React from 'react';
import { useNavigate } from 'react-router-dom';
import logoImage from '../Assets/WebDimension.png';
import logo from '../Assets/Logo_text.png';
import privacyPdf from "../Assets/Privacy_Policy.pdf"

function Sidebar({ setActivePage, activePage, isMobile, closeSidebar, setIsSidebarOpen }) {
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setActivePage(page);
    if (isMobile) {
      closeSidebar();
    }
  };

  return (
    <div className={`${isMobile?"w-screen":""} bg-[#1e293b] p-4 flex flex-col justify-between h-screen relative`}>
      {isMobile && (
        <button 
          className="absolute top-2 right-2 text-white text-2xl"
          onClick={() => setIsSidebarOpen(false)}
        >
          ×
        </button>
      )}
      <div className={`${!isMobile?"min-w-48":""}`}>
        {/* <h1 className="text-xl sm:text-2xl font-bold mb-6 text-white">Smart QR Codes</h1>
         */}
         <a href='/qr-screen'>
         <img src={logo} className="h-12 w-40 my-4 mb-12" ></img>
         </a>
        {/* <button 
          className="w-full bg-blue-600 text-white py-2 px-4 rounded mb-4 text-left"
          
        >
          + Create QR Code
        </button> */}
        <nav>
          <ul className="space-y-2">
            <li className={(activePage === "QRCodes") ? 'bg-[#334155] text-white font-medium p-2 rounded-md' : 'p-2 text-gray-300'}>
              <button onClick={() => handlePageChange('QRCodes')} className="w-full text-left">QR Codes</button>
            </li>
            <li className={(activePage === "AiCodes") ? 'bg-[#334155] text-white p-2 rounded-md' : 'p-2 text-gray-300'}>
              <button onClick={() => handlePageChange('AiCodes')} className="w-full text-left">AI Codes</button>
            </li>
            <li className={(activePage === "Settings") ? 'bg-[#334155] text-white p-2 rounded-md' : 'p-2 text-gray-300'}>
              <button onClick={() => handlePageChange('Settings')} className="w-full text-left">Settings</button>
            </li>
          </ul>
        </nav>
      </div>
      <div>
        {/* <div>
          <a href={privacyPdf} target='_blank' className="text-indigo-600 cursor-pointer">Privacy Policy</a>
        </div> */}
        <button 
          className="w-full bg-[#9BEC00] text-black font-bold py-2 px-4 rounded mb-12" 
          onClick={() => { navigate("/premium") }}
        >
          Get Full Access
        </button>
        <img 
          src={logoImage} 
          alt="Powered By Web Dimension" 
          className='h-[70px] w-full max-w-[140px] mb-4'
        />
        <p className='text-[#9BEC00] text-sm'>@ A Navneet Enterprise Company.</p>
      </div>
    </div>
  );
}

export default Sidebar;