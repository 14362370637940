import http from "./http-common";

class RestApi {
  // User APIs
  validateAddress(data) {
    return http.post("/validateAddress", data);
  }
  signin(data) {
    return http.post("/signin", data);
  }
  googleSignin(data) {
    return http.post("/googleSignin", data);
  }
  changePassword(data) {
    return http.post("/changePassword", data);
  }
  signUp(data){
    return http.post("/signup", data);
  }
  googleSignUp(data){
    return http.post("/googleSignup", data);
  }
  createQR(data){
    return http.post("/createQr", data);
  }
  getUrlLink(data){
    return http.get(`/redirect/${data.qr_short_id}`)
  }
  updateQr(data){
    return http.put("/updateQr", data);
  }
  getQrById(data){
    return http.post("/fetchQrById", data);
  }
  getQrByUserid(data){
    return http.post("/fetchQrByUserid", data);
  }
  buyPremium(data){
    return http.post("/createPayment", data);
  }
  verifyPaymentStatus(data){
    return http.post("/verifyPayment", data);
  }
  updatePaymentStatus(data){
    return http.post("/updatePayment", data);
  }
  verifyEmail(token){
    return http.get(`/verify-email?token=${token}`);
  }
  resetPasswordRequest(data){
    return http.post("/resetPasswordRequest", data);
  }
  resetPassword(data){
    return http.post(`/reset-password`,data);
  }
  resetPasswordToken(token){
    return http.get(`/reset-password?token=${token}`)
  }
  updateUser(data){
    return http.post('/updateUser', data)
  }
}

export default new RestApi();
