import React, { useState } from 'react';
import Sidebar from './components/Sidebar';
import QRCodes from './pages/QrCodeScreen';
import useMediaQuery from './hooks/useMediaQuery';
import AiCodes from './pages/AiCodes';
import Settings from './pages/Settings';
import logo from './Assets/Logo_text.png'

function App() {
  const [activePage, setActivePage] = useState('QRCodes');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const renderPage = () => {
    switch (activePage) {
      case 'QRCodes':
        return <QRCodes />;
      case 'AiCodes':
        return <AiCodes />;
      case 'Settings':
        return <Settings />;
      default:
        return <QRCodes />;
    }
  };

  return (
    <div className="flex bg-[#0f172a] text-white min-h-screen ">
      <div className={`${isMobile ? 'fixed inset-y-0 left-0 z-50 transition-transform duration-300 ease-in-out transform' : ''} ${isMobile && !isSidebarOpen ? '-translate-x-full' : 'translate-x-0'}`}>
        {(isSidebarOpen || !isMobile) && (
          <Sidebar 
            activePage={activePage}
            setActivePage={setActivePage} 
            closeSidebar={() => setIsSidebarOpen(false)}
            isMobile={isMobile}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        )}
      </div>
      <div className={`flex-1 overflow-y-auto`}>
        {isMobile && (
          <div className="flex justify-between items-center">
            <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="text-2xl m-4">
            ☰
          </button>
          <img src={logo} alt="Logo" className="h-10 w-40 mx-4"/>
          </div>
        )}
        {renderPage()}
      </div>
      {/* Overlay for mobile when sidebar is open */}
      {isMobile && isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
}

export default App;