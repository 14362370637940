import React, { useState } from 'react'
import RestApi from '../Apis/RestApi';
import { toast } from 'react-toastify';

function ResetPasswordRequest() {
    const [email, setEmail]=useState();
    const handleFormSubmit=async(e)=>{
        e.preventDefault();
       try{
        let data={
          email:email
      }
      const response=await RestApi.resetPasswordRequest(data);
      if(response.status){
        toast.success("Reset password Requested")
      }
      else{
        toast.error("Error, try again later!")
      }
       }catch(error){
        toast.error(error.response.data.msg)
       }
    }
  return (
    <div className='h-screen w-screen bg-slate-900 flex justify-center items-center'>
    <div className=' '>
    <h1 className='text-white text-5xl mb-8' >Reset Password</h1>
    <h2 className='text-white'>Provide Your Email for reset password Link</h2>
    <form className="space-y-4" onSubmit={handleFormSubmit}>
        <div>
            <label htmlFor="email" className="block text-sm font-medium text-white">Email</label>
            <input
            type="email"
            id="email"
            value={email}
            placeholder='Email'
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full pl-4 text-black rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-[2.5rem] leading-8"
              />
        </div>
             
              <button type='submit' className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
            Reset Password
        </button>
    </form>
    </div>
   </div>
  )
}

export default ResetPasswordRequest