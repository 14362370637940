import React, { useState, useEffect } from 'react';
import { signInWithGoogle } from '../firebase';
import Photo from '../Assets/Photo1.png';
import { useNavigate } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import { publicIpv4 } from 'public-ip';
import RestApi from '../Apis/RestApi';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import termsPdf from '../Assets/Smart_QrCodes_TnC.pdf';
import privacyPdf from '../Assets/Privacy_Policy.pdf';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error('Please fill in both email and password.');
      return;
    }

    if (!validateEmail(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    if (!termsAccepted) {
      toast.error('Please accept the terms and conditions.');
      return;
    }

    try {
      const Address = `${await publicIpv4()}_${isBrowser ? "web" : "mobile"}`;
      let data = { email, password, address: Address };
      const response = await RestApi.signin(data);
      if (response.data.status) {
        if (response.data.msg === "Successfully logged in.") {
          localStorage.setItem("_id", response.data.data.userObj._id);
          localStorage.setItem("email", response.data.data.userObj.email);
          localStorage.setItem("type", response.data.data.type);
          localStorage.setItem("username", response.data.data.userObj.name);
          localStorage.setItem("first_login", response.data.data.userObj.first_login);
          if(response.data.data.userObj.email_verified===false){
            toast.error("Email not Verified, Please Verify your email first.")
            return 
          }
          login(response.data.data.userObj._id);
          toast.success(response.data.msg);
          const firstLogin = JSON.parse(localStorage.getItem("first_login"));

          return navigate(firstLogin === false ? '/premium' : '/qr-screen');
        } else {
          toast.error("Invalid Login Credentials");
        }
      } else {
        toast.error("Internal server Error");
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error(error.response.data.err);
    }
  };

  const handleGoogleSignIn = () => {
    if (!termsAccepted) {
      toast.error('Please accept the terms and conditions.');
      return;
    }

    signInWithGoogle();
  };

  // useEffect(() => {
  //   const checkGoogleSignIn = async () => {
  //     try {
  //       const result = await getGoogleSignInResult();
  //       if (result) {
  //         const user = result.user;
  //         const Address = `${await publicIpv4()}_${isBrowser ? "web" : "mobile"}`;
  //         let data = { email: user.email, address: Address };

  //         const response = await RestApi.googleSignin(data);
  //         if (response.data.status) {
  //           if (response.data.msg === "Successfully logged in.") {
  //             await Promise.all([
  //               localStorage.setItem("_id", response.data.data.userObj._id),
  //               localStorage.setItem("email", response.data.data.userObj.email),
  //               localStorage.setItem("username", response.data.data.userObj.name),
  //               localStorage.setItem("type", response.data.data.type)
  //             ]);

  //             toast.success(response.data.msg);
  //             navigate(response.data.data.userObj.first_login === false ? '/premium' : '/qr-screen');
  //           } else {
  //             toast.error("Invalid Login Credentials");
  //           }
  //         } else {
  //           toast.error("Internal server Error");
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error during Google sign-in:", error);
  //       toast.error("Unable to Sign In using Google");
  //     }
  //   };

  //   checkGoogleSignIn(); // Handle Google sign-in after redirect
  // }, [navigate]);

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="flex w-full max-w-4xl rounded-lg bg-white shadow-lg flex-col md:flex-row h-[75vh]">
        <div className="w-full md:w-1/2 p-8">
          <h2 className="text-3xl font-bold mb-4">Welcome back!</h2>

          <form className="space-y-4" onSubmit={handleFormSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-[2.5rem] leading-8"
              />
            </div>
            
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-[2.5rem] leading-8"
              />
            </div>
            <div className="flex items-center justify-between">
              {/* <div className="flex items-center">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
                <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div> */}
              <div className="text-sm">
                <span onClick={() => navigate('/forget-password')} className="font-medium text-indigo-600 cursor-pointer hover:text-indigo-500">Forgot your password?</span>
              </div>
            </div>
            <div className="flex items-center">
              <input
                id="terms"
                name="terms"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                checked={termsAccepted}
                onChange={handleTermsChange}
              />
              <label htmlFor="terms" className="ml-2 block text-sm text-gray-900">
                I accept the <a href={termsPdf} target='_blank' className="text-indigo-600 cursor-pointer">terms and conditions</a> &nbsp; && <a href='/doc/privacy-policy' target='_blank' className="text-indigo-600 cursor-pointer">Privacy Policy</a>
              </label>
            </div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Sign In
            </button>
          </form>

          <div className="mt-6">
            <button
              onClick={handleGoogleSignIn}
              className="w-full flex items-center justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google icon" className="mr-2" />
              Sign in with Google
            </button>
          </div>

          <div className="mt-6 text-center">
            <p className="text-sm text-gray-600">Don't have an account? <span onClick={() => navigate('/signup')} className="font-medium text-indigo-600 hover:text-indigo-500">Sign up</span></p>
          </div>
        </div>
        <div className="w-full md:w-1/2 bg-cover bg-center hidden md:block" style={{ backgroundImage: `url(${Photo})` }}>
          <div className="flex h-full w-full justify-center items-center">
            <img src={Photo} alt="Photo" className="w-full h-full object-cover" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
