import React from 'react';
import logo from '../Assets/Logo_text.png';

const FAQ = () => {
  const sections = [
    {
      title: "1. What is Smart QR Codes?",
      content: `"Smart QR Codes is a SaaS platform by Navneet Enterprise (Web Dimension) that allows users to create, manage, and track QR codes easily. Our platform is designed to help businesses and individuals harness the power of QR codes for marketing, events, and more."`
    },
    {
      title: "2. How do I create a QR code?",
      content: "To create a QR code, log in to your Smart QR Codes account, navigate to the “Create QR Code” section, and enter the desired information or URL. Customize the design if needed, and generate your QR code instantly."
    },
    {
      title: "3. What types of QR codes can I create?",
      content: "Our platform supports a variety of QR code types, including URLs, text, email, Wi-Fi, vCard, and more. Simply choose the type that suits your needs during the creation process."
    },
    {
        title: "4. Can I track the performance of my QR codes?",
        content: "Yes! Smart QR Codes provides detailed analytics for each QR code, including the number of scans, location of scans, and date-wise scan data. You can access these insights from the dashboard after logging in."
      },
    {
        title: "5. Is my data secure?",
        content: "Absolutely. We take data security seriously and employ industry-standard measures to ensure the safety of your information. For more details, please review our Privacy Policy here."
      },
      {
        title: "6. Can I customize my QR codes?",
        content: "Yes, you can fully customize your QR codes. We offer design options that let you change colors, add logos, and adjust the look and feel of your QR codes to align with your branding."
      },
      {
        title: "7. What should I do if I forget my password?",
        content: "If you forget your password, you can reset it by clicking the “Forgot Password” link on the login page. You will receive an email with instructions to create a new password."
      },
      {
        title: "8.  Do you offer refunds on subscriptions?",
        content: "No, we have a strict no-refund policy on all subscriptions. Once a subscription is purchased, it is non-refundable. We encourage you to carefully select your plan and contact support if you have any questions before purchasing."
      },
      {
        title: "9. Where can I find billing information or update my payment method?",
        content: "Billing information is accessible in the “Account Settings” section under “Billing.” From there, you can view your current plan, payment history, and update your payment method if needed."
      },
      {
        title: "10. How do I contact Smart QR Codes support?",
        content: "You can reach our support team by emailing [support@smart-qrcodes.com]. We aim to respond within 24-48 hours."
      },
      {
        title: "11. Where are legal disputes handled?",
        content: "All legal disputes related to Smart QR Codes are subject to the jurisdiction of the courts in Noida, Uttar Pradesh, India, as per our Terms and Conditions."
      },
      {
        title: "12. Can I upgrade or downgrade my subscription plan?",
        content: "Yes, you can upgrade or downgrade your subscription plan at any time by going to the “Account Settings” section. Any changes will take effect at the start of your next billing cycle."
      }, {
        title: "13. How do I delete my account?",
        content: "If you wish to delete your account, please contact our support team at [support@smart-qrcodes.com] with your request. Note that account deletion is permanent and will result in the loss of all data associated with your account."
      },

  ];

  return (
    <div className='bg-gray-800'>
           <div className="max-w-4xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-800 shadow-lg rounded-lg">
      <div className='flex justify-between'>
     <div>
     <h1 className="text-3xl font-bold mb-6 text-white">Frequently Asked Questions (FAQ)
     </h1>
     </div>
     <img src={logo}  alt='Smart Qr Codes' className='max-w-40 h-10'/>
      </div>
      {sections.map((section, index) => (
        <div key={index} className="mb-6">
          <h2 className="text-xl font-semibold mb-2 text-white">{section.title}</h2>
          <p className="text-white whitespace-pre-line">{section.content}</p>
        </div>
      ))}
   
    </div>
    </div>
  );
};

export default FAQ;