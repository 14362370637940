import React, { useEffect, useState } from 'react';

function ErrorPopUp({ message }) {
  const [msgState, setMsgState] = useState(false);

  useEffect(() => {
    if (message !== "") {
      setMsgState(true);

      const timer = setTimeout(() => {
        setMsgState(false); // Automatically close after 5 seconds
      }, 5000);

      // Cleanup the timer when the component unmounts or message changes
      return () => clearTimeout(timer);
    }
  }, [message]); // Re-run when the message changes

  const closeHandler = () => {
    setMsgState(false);
  };

  if (!msgState) {
    return null; // Don't render the popup if msgState is false
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
      <div className="relative bg-gray-900 rounded-lg p-6 w-full max-w-xl flex justify-evenly flex-col">
        <div className="bg-white h-full w-full p-4 rounded-lg">
          <h1 className="text-red-800">Error Message</h1>
          <p className="text-red-800">{message ? message : ""}</p>
        </div>
        <div className="flex justify-end w-full mt-4">
          <button className="px-8 py-2 bg-sky-700 rounded-lg" onClick={closeHandler}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorPopUp;
