import React, { useEffect, useState, useRef } from 'react';
import RestApi from '../Apis/RestApi';
import QrCodeCard from '../components/QrCodeCard';
import QrCodePopUp from '../components/QrCodePopUp';
import { nanoid } from 'nanoid';
import QRCodeStyling from 'qr-code-styling';
import QrDetails from '../components/QrDetails';
import ErrorPopUp from '../components/ErrorPopUp';
import { toast } from 'react-toastify';

const QrCodeScreen = () => {
  const [selectedQR, setSelectedQR] = useState(null);
  const [createQR, setCreateQR] = useState(false);
  const [link, setLink] = useState('');
  const userId = localStorage.getItem('_id');
  const [qrCodes, setQrCodes] = useState([]);
  const qrCodeRef = useRef(null);
  const [qrBase64, setQrBase64] = useState();
  const [edit, setEdit] = useState(false);
  const accountType = localStorage.getItem("type");

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const pageLimit = 3; // Set the limit of QR codes per page

  const fetchUserQr = async () => {
    const response = await RestApi.getQrByUserid({ user_id: userId });
    setQrCodes(response.data.data.slice().reverse());
  };

  useEffect(() => {
    fetchUserQr();
  }, []);


  
  const createHandler = () => {
    setEdit(false)
    setCreateQR(true);
  };

  const handleQRSelect = (qr) => {
    setSelectedQR(qr);
  };

  const handleClose = () => {
    setSelectedQR(null);
  };

  const handleGenerateQR = async (options) => {
    let qrCode = null;

    if (edit) {
      const data = {
        qr_short_id: selectedQR.qr_short_id,
        qr_url: options.link || selectedQR.qr_url,
      };

      const response = await RestApi.updateQr(data);
      if(response.data.status){
        toast.success("Qr Updated Successfully")
        setCreateQR(false);
        fetchUserQr();
      }else{
        toast.error("Error Updating Qr, Try Again Later!")
      }

    } else {
      const id = nanoid(8);
      const Rlink = `https://www.smart-qrcodes.com/api/redirect/${id}`;

      qrCode = new QRCodeStyling({
        width: 220,
        height: 220,
        data: Rlink,
        margin: 12,
        dotsOptions: {
          color: options.pixelColor ? options.pixelColor : "#000",
          type: options.selectStyle ? options.selectStyle : "square",
        },
        backgroundOptions: {
          color: options.backgroundColor ? options.backgroundColor : "#fff",
        },
        cornersSquareOptions: {
          color: options.squareColor ? options.squareColor : "#000",
        },
        image: options.logo ? options.logo : null,
      });

      qrCode.append(qrCodeRef.current);

      const blob = await qrCode.getRawData('png');
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async () => {
        const base64data = reader.result;
        setQrBase64(base64data);

        const data = {
          user_id: userId,
          qr_short_id: id,
          qr_url: options.link,
          qr_type: accountType,
          qr_image: base64data,
        };

        const response = await RestApi.createQR(data);
        if(response.data.status){
          toast.success("Qr Created Successfully");
          setCreateQR(false);
          fetchUserQr();
        }
        else{
          toast.error('Error Generating Qr, Try Again Later')
        }
      };
    }
  };

  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < Math.ceil(qrCodes.length / pageLimit)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  const paginatedQrCodes = qrCodes.slice((currentPage - 1) * pageLimit, currentPage * pageLimit);

  return (
    <div className="flex h-screen bg-[#0f172a] text-white overflow-hidden">
      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex-shrink-0 p-4 sm:p-6 border-b border-gray-700">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold">QR Codes</h2>
            <button className="bg-blue-500 text-white py-2 px-4 rounded" onClick={createHandler}>+ Create QR Code</button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4 sm:p-6">
          {createQR && (
            <QrCodePopUp setCreateQR={setCreateQR} handleGenerateQR={handleGenerateQR} setEdit={setEdit} selectedQR={selectedQR} edit={edit} />
          )}

        <div className="space-y-4">
            {paginatedQrCodes && paginatedQrCodes.length > 0 ? (
              paginatedQrCodes.map((qr) => (
                <QrCodeCard key={qr._id} qr={qr} handleQRSelect={handleQRSelect} setEdit={setEdit} setCreateQR={setCreateQR} />
              ))
            ) : (
              <p className="text-gray-500">No QR codes available.</p>
            )}
          </div>

          {/* Pagination Controls */}
          {paginatedQrCodes && (paginatedQrCodes.length >= 2 || currentPage>1) && (
            <div className="flex justify-between items-center mt-6">
              <button
                className={`bg-[#9BEC00] text-black py-2 px-4 rounded ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`}
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="text-white">
                Page {currentPage} of {Math.ceil(qrCodes.length / pageLimit)}
              </span>
              <button
                className={`bg-[#9BEC00] text-black py-2 px-4 rounded ${currentPage === Math.ceil(qrCodes.length / pageLimit) && 'opacity-50 cursor-not-allowed'}`}
                onClick={handleNextPage}
                disabled={currentPage === Math.ceil(qrCodes.length / pageLimit)}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>

      {/* QR Code details sidebar */}
      {/* {selectedQR && (
        <QrDetails handleClose={handleClose} selectedQR={selectedQR} />
      )} */}
    </div>
  );
};

export default QrCodeScreen;