import React, { useState, useEffect } from 'react';
import Type from '../Assets/type.png';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function QrCodePopUp({ setCreateQR, handleGenerateQR, selectedQR, edit }) {
  const [selectStyle, setSelectStyle] = useState("classic");
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [squareColor, setSquareColor] = useState("#000000");
  const [pixelColor, setPixelColor] = useState("#000000");
  const [logo, setLogo] = useState(null);
  const [link, setLink] = useState(selectedQR?.qr_url || "");
  const [userType, setUserType] = useState(null);
  const navigate = useNavigate();

  
  
  useEffect(() => {
    const storedType = localStorage.getItem('type');
    setUserType(storedType);
  }, []);

  const handleStyleSelection = (option) => {
    setSelectStyle(option);
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogo(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    if(link===''){
      toast.error("Link can't be empty.")
      return;
    }
    const options = {
      link,
      selectStyle,
      backgroundColor,
      squareColor,
      pixelColor,
      logo
    };
    handleGenerateQR(options);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 p-4">
      <div className={`bg-[#223153] rounded-lg p-6 w-full max-w-5xl max-h-[90vh] overflow-y-auto ${edit ? "max-w-lg" : ""}`}>
        <div className="flex flex-col lg:flex-row">
          <div className={`${edit ? "w-full" : "w-full lg:w-[40%] lg:mr-4"}`}>
            <h2 className="text-lg font-bold text-white mb-4">
              {edit ? "Edit your QR Code" : "Add New QR Code Link"}
            </h2>
            <input
              type="text"
              placeholder="Enter the link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              className="w-full px-3 py-2 border text-white bg-slate-600 border-gray-800 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="mt-6 flex justify-center items-center min-h-72 bg-gray-400 rounded-lg">
              {selectedQR?.qr_image && edit ? (
                <img src={selectedQR.qr_image} alt="QR Code" className="rounded-2xl m-4 max-h-full max-w-full object-contain" />
              ) : (
                <p className="text-center">Your QR Code will appear here</p>
              )}
            </div>
            {(edit || userType === "Normal") && (
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => setCreateQR(false)}
                  className="bg-slate-500 text-black p-2 hover:bg-slate-700 rounded-lg px-[10px] m-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  className="bg-blue-800 text-white p-2 hover:bg-blue-600 rounded-lg px-[10px] m-2"
                >
                  {userType === "Normal" ? "Generate QR" : "Save"}
                </button>
              </div>
            )}
          </div>

          {!edit && (
            <div className={`w-full lg:w-[58%] bg-[#17243b] rounded-lg p-2 relative mt-4 lg:mt-0 ${userType === 'Normal' ? 'blur-sm' : ''}`}>
              <h2 className="text-white text-lg font-bold p-2">Customize Option</h2>

              <div className="rounded-lg p-2">
                <h3 className="text-white underline underline-offset-4 mb-2">Style and Margin</h3>
                <div className="flex flex-wrap justify-center">
                  {['classic', 'rounded', 'thin', 'smooth', 'circles'].map((style) => (
                    <div
                      key={style}
                      className={`m-1 cursor-pointer rounded-lg ${selectStyle === style ? "border-2 border-green-600" : ""}`}
                      onClick={() => handleStyleSelection(style)}
                    >
                      <img src={Type} className="rounded-lg w-24 h-24 object-cover" alt={`${style} style`} />
                      <p className={`text-center text-white text-sm ${selectStyle === style ? "bg-green-600" : ""}`}>{style}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="rounded-lg p-2">
                <h3 className="text-white underline underline-offset-4 mb-2">Color</h3>
                <div className="flex flex-wrap justify-between p-1">
                  {[
                    { label: 'Background', value: backgroundColor, setter: setBackgroundColor },
                    { label: 'Squares', value: squareColor, setter: setSquareColor },
                    { label: 'Pixels', value: pixelColor, setter: setPixelColor },
                  ].map(({ label, value, setter }) => (
                    <div key={label} className="w-full sm:w-2/6 mb-2">
                      <label className="text-white block">{label}</label>
                      <input
                        type="color"
                        value={value}
                        onChange={(e) => setter(e.target.value)}
                        className="w-[90%] h-8 border-none cursor-pointer"
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="rounded-lg p-2">
                <h3 className="text-white underline underline-offset-4 mb-2">Logo</h3>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoUpload}
                  className="text-white p-1 w-full"
                />
                {logo && (
                  <div className="p-1 mt-2">
                    <img src={logo} alt="Logo preview" className="h-16 w-16 object-contain" />
                    <button
                      onClick={() => setLogo(null)}
                      className="bg-[#9d3434] text-white py-1 px-4 rounded-lg hover:bg-red-700 mt-2"
                    >
                      Remove Logo
                    </button>
                  </div>
                )}
              </div>

              <div className="flex justify-end mt-4">
                <button
                  onClick={() => setCreateQR(false)}
                  className="bg-slate-500 text-black p-2 hover:bg-slate-700 rounded-lg px-[10px] m-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  className="bg-blue-800 text-white p-2 hover:bg-blue-600 rounded-lg px-[10px] m-2"
                >
                  Save
                </button>
              </div>
            </div>
          )}

          {(userType === 'Normal' && !edit) && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 z-20 rounded-lg">
              <div className="text-white text-center">
                <h3 className="text-2xl mb-4">Unlock Premium Features!</h3>
                <button
                  className="bg-yellow-500 text-black py-2 px-4 rounded-lg hover:bg-yellow-600"
                  onClick={() => navigate('/premium')}
                >
                  Buy Premium
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QrCodePopUp;