import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ModalProvider } from "styled-react-modal";
import { AuthProvider, useAuth } from "./context/AuthContext";
import ProtectedRoute from "./routing/ProtectedRoute";

import Signup from "./pages/Signup";
import Login from "./pages/Login";
import App from "./App";
import './index.css';
import ProUpgradeCard from "./pages/ProUpgradeCard";
import EmailVerification from "./pages/EmailVerificationPage";
import ChangePassword from "./components/ChangePassword";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import ResetPasswordRedirect from "./pages/ResetPasswordRedirect";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // Add this import
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Home from "./pages/Home";
import FAQ from "./pages/FAQ";

const RoutingComponents = () => (
  <BrowserRouter>
    <ModalProvider>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/doc/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path='/doc/faq' element={<FAQ/>}/>
        <Route
          path="/qr-screen"
          element={<ProtectedRoute element={<App />} />}
        />
        <Route
          path="/premium"
          element={<ProUpgradeCard />}
        />
        <Route path="*" element={<Navigate replace to="/" />} />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route path="/forget-password" element={<ResetPasswordRequest />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/resetpassword" element={<ResetPasswordRedirect />} />
      </Routes>
    </ModalProvider>
  </BrowserRouter>
);

// Render the app with AuthProvider
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RoutingComponents />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" // Add this line to set a default theme
      />
    </AuthProvider>
  </React.StrictMode>
);