import React, { useState } from 'react';
import { signInWithGoogle } from '../firebase';
import Photo from '../Assets/Photo1.png'
import RestApi from '../Apis/RestApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const Signup = () => {
  const [formValues, setFormValues] = useState({
    fullName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const Navigate=useNavigate();

  const [errors, setErrors] = useState({});

    const handleGoogleSignIn = async () => {
    
        try {
          const result = await signInWithGoogle();
          const user = result.user;
          let data={
            email:user.email,
            username:user.displayName
          }
          // Send user info to your API
          const response = await RestApi.googleSignUp(data)
          if (response.data.status) {
            toast.success("Signup Successfully");
           Navigate('/qr-screen')
          } else {
            // Handle error
            toast.error("Error while singup with google");
            console.log("Error while singup with google")
        }
        } catch (error) {
          toast.error("Internal Server Error, Please Try Again Later!")
          console.error("Error during Google sign-in:", error);
        }
    // try {
    //  let googleSignUp= await signInWithGoogle();
    //  console.log(googleSignUp,"this signup is from google")
    //   // Redirect to your desired page after successful sign-in
    // } catch (error) {
    //   console.error("Google sign-in error", error);
    // }
  };

  const validate = () => {
    let errors = {};

    if (!formValues.fullName.trim()) {
      errors.fullName = 'Full name is required';
    }

    if (!formValues.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = 'Email address is invalid';
    }

    if (!formValues.password) {
      errors.password = 'Password is required';
    } else if (formValues.password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    }

    if (!formValues.confirmPassword) {
      errors.confirmPassword = 'Confirm your password';
    } else if (formValues.confirmPassword !== formValues.password) {
      errors.confirmPassword = 'Passwords do not match';
    }

    return errors;
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      try{
        let data={
          email:formValues.email,
          username:formValues.fullName,
          password:formValues.password
        }
        let response=await RestApi.signUp(data);
        if(response.data.status){
          toast.success("Successfully Signed Up")
          Navigate(`/verify-email`)
        }
        else{
          toast.error("Unable to Sign Up, Please Try Again Later")
        }
      }catch(error){
        console.log(error)
        toast.error("Internal Server Error, Please Try Again Later")
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <div className="flex w-full max-w-4xl rounded-lg bg-white shadow-lg ">
        <div className="w-1/2 p-8">
          <h2 className="text-3xl font-bold mb-4">Create your account</h2>
          <p className="mb-6">Fill in the information below to create your account.</p>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full Name</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-[2rem]"
                value={formValues.fullName}
                onChange={handleChange}
              />
              {errors.fullName && <p className="text-red-500 text-xs mt-1">{errors.fullName}</p>}
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-[2rem]"
                value={formValues.email}
                onChange={handleChange}
              />
              {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-[2rem]"
                value={formValues.password}
                onChange={handleChange}
              />
              {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-[2rem]"
                value={formValues.confirmPassword}
                onChange={handleChange}
              />
              {errors.confirmPassword && <p className="text-red-500 text-xs mt-1">{errors.confirmPassword}</p>}
            </div>
            <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Sign Up
            </button>
          </form>

          <div className="mt-6">
            <button onClick={handleGoogleSignIn} className="w-full flex items-center justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
              <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google icon" className="mr-2" />
              Sign up with Google
            </button>
          </div>

          <div className="mt-6 text-center">
            <p className="text-sm text-gray-600">Already have an account? <span onClick={() => Navigate('/login')} className="font-medium text-indigo-600 hover:text-indigo-500">Sign in</span></p>
          </div>
        </div>
        <div className="w-1/2 bg-cover bg-center" style={{ backgroundImage: 'url(/path-to-your-image.png)' }}>
          <div className="flex h-full items-center justify-center">
            <img src={Photo} alt="Karina" className="w-3/4 object-cover" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
